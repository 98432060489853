import Logo from "../../assets/svgs/Logo.svg";
import YT from "../../assets/svgs/Youtube.svg";
import Insta from "../../assets/svgs/Insta.svg";
import Twitter from "../../assets/svgs/Twitter.svg";
import LinkedIn from "../../assets/svgs/LinkedIn.svg";

import { Link } from "react-router-dom";

import ItemsCard from "./ItemsCard";

const MobileFooter = () => {
    const ResourcesItems = [
        {
            name: "Blogs",
            url: "/",
        },
        {
            name: "Articles",
            url: "/",
        },
        {
            name: "Research Papers",
            url: "/",
        },
        {
            name: "White Papers",
            url: "/",
        },
    ];
    const LegalItems = [
        {
            name: "Terms of Service",
            url: "/terms-and-conditions",
        },
        {
            name: "Privacy Policy",
            url: "/",
        },
    ];
    const QuickLinksItems = [
        {
            name: "About Us",
            url: "/about-us",
        },
        {
            name: "Pricing",
            url: "/",
        },
        {
            name: "FAQs",
            url: "/",
        },
        {
            name: "Login",
            url: "https://buddy.vils.ai/auth",
        },
        {
            name: "Register",
            url: "https://buddy.vils.ai/auth",
        },
        {
            name: "Contact Us",
            url: "/contact-us",
        },
    ];
    return (
        <div className="ps-[5%] pr-[5%] flex flex-col md:flex-row gap-10 mt-20 cursor-pointer">
            <div className="  flex flex-col gap-4">
                <Link to="/">
                    <img src={Logo} alt="logo" className="w-20 mb-6" />
                </Link>
                <ItemsCard items={QuickLinksItems} label="Quick Links" />
                <ItemsCard items={ResourcesItems} label="Resources" />
                <ItemsCard items={LegalItems} label="Legal" />
                <p className="w-[70%] my-6">
                    Plot No.5, Survey No 62, 3rd Floor, Lake Front Towers,
                    Inorbit Mall Rd, Hitech city, Madhapur, Telangana, India,
                    500081
                </p>
                <div className="flex gap-4 h-15 mb-4 ">
                    <a
                        href="https://www.youtube.com/@VILS-AI"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={YT} alt="yt" />
                    </a>
                    <a
                        href="https://www.instagram.com/vils.ait/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={Insta} alt="insta" />
                    </a>
                    <a
                        href="https://twitter.com/VILSAITPVT"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={Twitter} alt="twitter" />
                    </a>
                    <a
                        href="https://www.linkedin.com/company/vils-ait/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={LinkedIn} alt="linkedin" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default MobileFooter;
