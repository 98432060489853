import { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

interface ItemsCardProps {
    label: string;
    items: ItemsProps[];
}

interface ItemsProps {
    name: string;
    url: string;
}

const ItemsCard: React.FC<ItemsCardProps> = ({ label, items }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className=" border-b-2 border-white pb-4">
            <div
                className="flex justify-between items-center"
                onClick={() => setIsOpen(!isOpen)}
            >
                <h3 className="font-medium text-xl">{label}</h3>
                {isOpen ? <FaMinus /> : <FaPlus />}
            </div>

            <div
                className={` transition-all ease-in-out duration-700  ${
                    isOpen ? "opacity-100" : "opacity-0"
                }`}
            >
                {isOpen && (
                    <div className="mt-2 flex flex-col gap-3 text-[#C5C5C5]">
                        {items.map((e) => (
                            <Link to={e.url}>
                                <p>{e.name}</p>
                            </Link>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ItemsCard;
